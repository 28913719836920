import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import withFirebaseAuth from 'react-with-firebase-auth'
import Button from '../../components/Button'
import styles from './styles.module.css'

import firebase from '../../firebase'

const firebaseAppAuth = firebase.auth()

const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
}

const allowedDomains = /\.*@(pagar\.me|stone\.com\.br|mundipagg\.com|equals\.com\.br|cappta\.com\.br|arpexcapital\.com\.br|registradoratag\.com\.br|taginfraestrutura\.com\.br)/i
const isDomainAllowed = email => allowedDomains.test(email)

const Login = ({
  history,
  user,
  signInWithGoogle,
}) => {

  useEffect(() => {
    if (user && isDomainAllowed(user.email)) {
      return history.push('/coupons')
    }
    if (user && !isDomainAllowed(user.email)) {
      return history.push('/unauthorized')
    }
  }, [user, history])

  return (
    <div className={styles.content}>
        <div className={styles.loginButton}>
          <Button size="small" onClick={signInWithGoogle}>Entre com o Google</Button>
      </div>
    </div>
  )
}

Login.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
  signOut: PropTypes.func.isRequired,
  signInWithGoogle: PropTypes.func.isRequired,
}

Login.defaultProps = {
  user: null,
}

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(Login);
